/* eslint-disable array-callback-return */
import { Box } from '@mui/material'
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useDraggable,
  useDroppable,
  useSensor,
} from '@dnd-kit/core'
import type { PointerEvent } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export function SortableElement(props: {
  children: React.ReactNode
  showTransition: boolean
  id: string
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: props.showTransition ? transition : undefined,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'lightgrey',
    },
  }

  return (
    <Box ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {props.children}
    </Box>
  )
}

export function DraggableElement(props: {
  children: React.ReactNode
  id: string
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  })
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        zIndex: 10000,
      }
    : undefined

  return (
    <Box ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </Box>
  )
}

export function DroppableElement(props: {
  children: React.ReactNode
  id: string
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  })

  return (
    <Box
      ref={setNodeRef}
      sx={{
        backgroundColor: isOver ? 'lightgrey' : 'transparent',
      }}
    >
      {props.children}
    </Box>
  )
}

class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          (event.target as any).tagName.toLowerCase() === 'path' // MUI SVG icons
        ) {
          return false
        }

        return true
      },
    },
  ]
}

export function DndContextWithSensor(
  props: React.ComponentProps<typeof DndContext>
) {
  const isMobile = window.innerWidth < 768

  const mobileSensors = [useSensor(TouchSensor)]
  const desktopSensors = [useSensor(MyPointerSensor), useSensor(KeyboardSensor)]

  const sensors = isMobile ? mobileSensors : desktopSensors

  return (
    <DndContext sensors={sensors} {...props}>
      {props.children}
    </DndContext>
  )
}
